import {
	CreateIssueMessageRequest,
	CreateIssueTrackerFilterRequest,
	IssueTrackerFilter,
	UpdateIssueRequest,
	UpdateIssueTrackerFilterRequest,
} from '@nimbly-technologies/nimbly-common';
import {
	apiURL,
	NIMBLY_API_ISSUES_BULK_EDIT,
	NIMBLY_API_ISSUES_BULK_MESSAGE,
	NIMBLY_API_ISSUES_CUSTOM_FILTER,
} from 'config/baseURL';
import { DeptApprover } from 'core/domain/models/issue/issueApprovalModel';
import { issueApprovalRepository } from 'core/infrastructure/repositories/issue/issueApprovalRepository';
import API from 'helpers/api';
import { ApprovalDetailsI, ApproversListI } from 'reducers/issues/issues.reducer';

export type BulkEditIssuesData = Pick<
	UpdateIssueRequest,
	'status' | 'priorityV2' | 'severity' | 'dueDate' | 'assignedDepartments' | 'assignedTo'
>;

export type BulkEditIssuesPayloadData = Partial<BulkEditIssuesData>;

export type bulkIssueMessageType = Pick<
	CreateIssueMessageRequest,
	'createdAt' | 'createdBy' | 'createdByName' | 'message' | 'messageType'
>;

export const bulkEditIssues = async (issueIDs: string[], changedValues: BulkEditIssuesPayloadData) => {
	const url = NIMBLY_API_ISSUES_BULK_EDIT;
	const token = await API.getFirebaseToken();
	const payload = {
		issueIDs,
		data: {
			...changedValues,
			triggerNotification: true,
		},
	};
	const response = await API.put(url, token, payload);
	const json = await response.json();

	if (response.status !== 200) {
		throw json.message;
	}
	return json.data;
};

export const bulkSubmitIssueMesage = async (issueIDs: string[], data: bulkIssueMessageType) => {
	const url = NIMBLY_API_ISSUES_BULK_MESSAGE;
	const token = await API.getFirebaseToken();
	const payload = {
		issueIDs,
		data: {
			...data,
			triggerNotification: true,
		},
	};
	const response = await API.post(url, token, payload);
	const json = await response.json();

	if (response.status !== 200) {
		throw json.message;
	}
	return json.data;
};

export const getIssueCustomFilter = async (): Promise<IssueTrackerFilter[]> => {
	const url = NIMBLY_API_ISSUES_CUSTOM_FILTER;
	const token = await API.getFirebaseToken();

	const response = await API.get(url, token);
	const json = await response.json();

	if (response.status !== 200) {
		throw json.message;
	}
	return json.data;
};

export const saveIssueCustomFilter = async (payload: CreateIssueTrackerFilterRequest) => {
	const url = NIMBLY_API_ISSUES_CUSTOM_FILTER;
	const token = await API.getFirebaseToken();

	const response = await API.post(url, token, payload);
	const json = await response.json();

	if (response.status !== 200) {
		throw json.message;
	}
	return json.data;
};

export const updateIssueCustomFilter = async (filterId: string, payload: UpdateIssueTrackerFilterRequest) => {
	const url = `${NIMBLY_API_ISSUES_CUSTOM_FILTER}/${filterId}`;
	const token = await API.getFirebaseToken();

	const response = await API.put(url, token, payload);
	const json = await response.json();

	if (response.status !== 200) {
		throw json.message;
	}
	return json.data;
};

export const deleteIssueCustomFilter = async (filterId: string) => {
	const url = `${NIMBLY_API_ISSUES_CUSTOM_FILTER}/${filterId}`;
	const token = await API.getFirebaseToken();

	const response = await API.delete(url, token);
	const json = await response.json();

	if (response.status !== 200) {
		throw json.message;
	}
	return json.data;
};

export const postApprovalRequest = async (
	issueId: string,
	approvalType: string,
	approvers: string[],
	optionalNote: string,
) => {
	const authToken = await API.getFirebaseToken();
	const url = `${apiURL}/issues/issueApproval/create-request`;
	const payload = {
		issueID: issueId,
		approvalType: approvalType,
		approvers: approvers,
		comments: optionalNote,
	};
	const options = {
		method: 'POST',
		headers: {
			Authorization: authToken,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(payload),
	};

	const response = await fetch(url, options);

	if (response.status !== 200) return 'FAILED';
	const responseData = await response.json();
	return 'SUCCESS';
};

export const fetchIssueApprovalData = async (issueID: string): Promise<ApprovalDetailsI | undefined> => {
	return issueApprovalRepository.getApprovalData(issueID);
};

export const postApprovalReview = async (
	requestId: string,
	issueId: string,
	status: string,
	comments: string,
	departmentID: string | undefined,
) => {
	return issueApprovalRepository.approveRequest({ requestId, issueId, status, comments, departmentID });
};

export const patchCancelRequest = async (issueId: string): Promise<string> => {
	return issueApprovalRepository.cancelApprovalRequest({ issueId });
};

export const fetchApproverHistoryDetails = async (issueID: string, id: string, type: 'memberID' | 'departmentID') => {
	return issueApprovalRepository.getApproverHistory({ issueId: issueID, id: id, type: type });
};

export const fetchApproversList = async (requesterID: string): Promise<ApproversListI[] | undefined> => {
	return issueApprovalRepository.getApproversList({ requesterId: requesterID });
};

export const patchAddApprover = async (issueID: string, userID: string[] | DeptApprover[]): Promise<string> => {
	return issueApprovalRepository.addApprover({ issueId: issueID, userIds: userID });
};

export const patchRemoveApprover = async (issueID: string, userID: string | DeptApprover) => {
	return issueApprovalRepository.removeApprover({ issueId: issueID, userId: userID });
};
