import { neutral } from '../styles';

const generalSelectTheme = (theme) => ({
	...theme,
	colors: {
		...theme.colors,
		primary: '#5049B2',
		primary75: '#716bc3',
		primary50: '#948fd2',
		primary25: '#b7b3e1',
	},
});

const input = (provided) => ({
	...provided,
	fontSize: '13px',
	fontWeight: 400,
	color: '#535353',
	maxWidth: '300px',
});

const singleValue = (provided) => ({
	...provided,
	fontSize: '13px',
	fontWeight: 400,
	color: '#535353',
});

const placeholder = (provided) => ({
	...provided,
	fontSize: '13px',
	color: '#a8a8aa',
});
const option = (provided, state) => ({
	...provided,
	fontSize: '13px',
	color: state.isSelected ? '#ffffff' : '#535353',
});

const menu = (provided) => ({
	...provided,
	maxHeight: '8em',
});

const menuList = (provided) => ({
	...provided,
	maxHeight: '8em',
});

const control = (provided) => ({
	...provided,
	borderColor: '#a8a8aa',
});

const noOptionsMessage = (provided) => ({
	...provided,
	textAlign: 'left',
	fontSize: '13px',
	padding: '4px',
	color: '#a8a8aa',
});

const customStyles = (stylesObj) => (provided) => ({
	...provided,
	...stylesObj,
});

const emailSelectStyles = {
	control,
	input,
	menu,
	menuList,
	noOptionsMessage,
	option,
	placeholder,
	singleValue,
};

const generalSelectStyles = {
	control,
	input,
	noOptionsMessage,
	option,
	placeholder,
	singleValue,
};

const recipientSelectStyles = {
	noOptionsMessage,
	option,
	control: (provided, state) => ({
		...provided,
		minHeight: '40px',
		marginTop: '20px',
		borderWidth: 0,
		borderRadius: 2,
		backgroundColor: '#fff',
		opacity: state.isDisabled ? 0.5 : 1,
	}),
	input: customStyles({
		fontSize: '12px',
		color: '#a8a8aa',
	}),
	placeholder: customStyles({
		fontSize: '12px',
		color: '#a8a8aa',
	}),
	multiValueLabel: customStyles({
		fontSize: '12px',
		color: '#535353',
	}),
	multiValueRemove: (styles) => ({
		...styles,
		':hover': {
			backgroundColor: '#ccc',
			color: 'white',
		},
	}),
};

const issueSelectStyles = {
	noOptionsMessage,
	menu: customStyles({
		fontSize: '12px',
		lineHeight: '18px',
		marginTop: 3,
	}),
	option: customStyles({
		fontSize: '12px',
		lineHeight: '18px',
		paddingTop: 6,
		paddingBottom: 6,
		minHeight: 30,
		maxHeight: 30,
	}),
	container: customStyles({
		minHeight: 20,
		maxHeight: 20,
	}),
	placeholder: customStyles({
		fontSize: '12px',
		lineHeight: '18px',
		color: '#a8a8aa',
		minHeight: 20,
		maxHeight: 20,
	}),
	control: customStyles({
		minHeight: 20,
		maxHeight: 20,
		minWidth: 90,
		background: '#F2F5F6',
		borderColor: 'transparent',
	}),
	input: customStyles({
		fontSize: '12px',
		lineHeight: '18px',
		fontWeight: 400,
		color: '#535353',
		marginTop: -3,
		minHeight: 20,
		maxHeight: 20,
		paddingRight: 5,
		paddingLeft: 5,
	}),
	singleValue: customStyles({
		fontSize: '12px',
		lineHeight: '18px',
		fontWeight: 400,
		color: '#535353',
		top: '0',
		transform: 'translate(0, 0)',
	}),
	valueContainer: customStyles({
		minHeight: 20,
		maxHeight: 20,
		top: -3,
	}),
	dropdownIndicator: customStyles({
		marginTop: -8,
	}),
};

const inventorySelectStyles = {
	input: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
	}),
	singleValue: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
		marginTop: 2,
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: 30,
		maxHeight: 30,
		minWidth: '50%',
		borderRadius: 2,
		borderColor: state.hasValue ? '#56C768' : '#FF5E6F',
	}),
	placeholder: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#b5b5b5',
		marginTop: -4,
	}),
	dropdownIndicator: customStyles({
		marginTop: -10,
	}),
	menu: customStyles({
		maxHeight: '10rem',
	}),
	menuList: customStyles({
		maxHeight: '10rem',
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	noOptionsMessage: customStyles({
		textAlign: 'center',
		fontSize: '12px',
	}),
};

const inventoryNewSelectStyles = {
	input: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
	}),
	singleValue: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
		marginTop: 2,
	}),
	control: customStyles({
		minHeight: 30,
		maxHeight: 30,
		minWidth: '50%',
		borderRadius: 2,
	}),
	placeholder: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#b5b5b5',
		marginTop: -4,
	}),
	dropdownIndicator: customStyles({
		marginTop: -10,
	}),
	menu: customStyles({
		maxHeight: '10rem',
	}),
	menuList: customStyles({
		maxHeight: '10rem',
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	noOptionsMessage: customStyles({
		textAlign: 'center',
		fontSize: '12px',
	}),
};

const poSelectStyles = {
	input: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
	}),
	singleValue: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
		marginTop: 2,
	}),
	placeholder: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#8f8f8f',
		marginTop: -4,
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: 30,
		maxHeight: 30,
		minWidth: '50%',
		borderColor: 'transparent',
		background: state.isDisabled ? '#FFF' : '#FAFAFA',
		borderRadius: 2,
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		marginTop: -10,
		opacity: state.isDisabled ? 0 : 1,
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	valueContainer: customStyles({
		paddingLeft: 5,
		paddingRight: 5,
	}),
};

const questionSelectStyles = {
	input: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
	}),
	singleValue: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
		marginTop: 2,
	}),
	placeholder: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#8f8f8f',
		marginTop: -4,
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: 30,
		maxHeight: 30,
		minWidth: '50%',
		borderColor: 'transparent',
		background: state.isDisabled ? '#efeeed' : '#F2F5F6',
		borderRadius: 2,
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		marginTop: -10,
		opacity: state.isDisabled ? 0 : 1,
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	valueContainer: customStyles({
		paddingLeft: 5,
		paddingRight: 5,
	}),
};

const questionnaireTemplateDownloadStyles = {
	input: customStyles({
		fontSize: '10px',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		margin: 0,
		padding: '6px 10px 6px 10px',
	}),
	singleValue: customStyles({
		fontSize: '10px',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		padding: '6px 10px 6px 10px',
	}),
	placeholder: customStyles({
		fontSize: '10px',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#8f8f8f',
		padding: '6px 10px 6px 10px',
	}),
	control: (provided, state) => ({
		...provided,
		background: '#f2f5f6',
		minHeight: 30,
		minWidth: '50%',
		borderColor: state.isFocused ? 'hsl(0,0%,80%)' : 'transparent',
		borderRadius: 2,
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		marginTop: '0px',
		padding: '0px 5px 0px 5px',
		opacity: state.isDisabled ? 0 : 1,
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		margin: 0,
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	valueContainer: customStyles({
		padding: 0,
	}),
};

const flagSelectStyles = {
	input: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
	}),
	singleValue: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
		marginTop: 2,
	}),
	placeholder: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#8f8f8f',
		marginTop: -4,
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: 30,
		maxHeight: 30,
		minWidth: '50%',
		borderColor: 'transparent',
		background: state.isDisabled ? '#efeeed' : '#F2F5F6',
		borderRadius: 2,
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		marginTop: -10,
		opacity: state.isDisabled ? 0 : 1,
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	valueContainer: customStyles({
		paddingLeft: 5,
		paddingRight: 5,
	}),
};

const weightScoreSelectStyles = {
	input: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
		width: '15px',
	}),
	singleValue: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
		marginTop: 2,
		marginRight: '10px',
	}),
	placeholder: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#8f8f8f',
		marginTop: -4,
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: 30,
		maxHeight: 30,
		minWidth: '100%',
		borderColor: 'transparent',
		background: state.isDisabled ? '#efeeed' : '#F2F5F6',
		borderRadius: 2,
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		marginTop: -10,
		opacity: state.isDisabled ? 0 : 1,
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	valueContainer: customStyles({
		paddingLeft: 5,
		paddingRight: 5,
	}),
	container: customStyles({
		marginRight: '10px',
	}),
};

const timezoneSelectStyles = {
	input: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#efeeed',
		height: '30px',
	}),
	singleValue: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
		marginTop: 2,
	}),
	placeholder: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#8f8f8f',
		marginTop: -4,
	}),
	control: (provided) => ({
		...provided,
		minHeight: 30,
		maxHeight: 30,
		minWidth: '50%',
		borderColor: 'transparent',
		background: '#f2f5f6',
		borderRadius: 2,
		boxShadow: 'none',
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		marginTop: -10,
		opacity: state.isDisabled ? 0 : 1,
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	valueContainer: customStyles({
		paddingLeft: 5,
		paddingRight: 5,
	}),
	menuList: customStyles({
		maxHeight: '100px',
	}),
};

const radiuSelectStyles = {
	input: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#efeeed',
		height: '30px',
	}),
	singleValue: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
		marginTop: 2,
	}),
	placeholder: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#8f8f8f',
		marginTop: -4,
	}),
	control: (provided) => ({
		...provided,
		minHeight: 30,
		maxHeight: 30,
		minWidth: '50%',
		borderColor: 'transparent',
		background: '#f2f5f6',
		borderRadius: 2,
		boxShadow: 'none',
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		marginTop: -10,
		opacity: state.isDisabled ? 0 : 1,
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	valueContainer: customStyles({
		paddingLeft: 5,
		paddingRight: 5,
	}),
	menuList: customStyles({
		maxHeight: '100px',
	}),
};

const siteSelectStyles = {
	input: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
	}),
	singleValue: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
		marginTop: 2,
	}),
	placeholder: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#8f8f8f',
		marginTop: -4,
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: 30,
		maxHeight: 30,
		minWidth: '50%',
		borderColor: 'transparent',
		background: state.isDisabled ? '#efeeed' : '#F2F5F6',
		borderRadius: 2,
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		marginTop: -10,
		opacity: state.isDisabled ? 0 : 1,
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	valueContainer: customStyles({
		paddingLeft: 5,
		paddingRight: 5,
	}),
	clearIndicator: customStyles({
		paddingTop: 0,
	}),
};

const userGroupSelectStyles = {
	input: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
	}),
	singleValue: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
		marginTop: 2,
	}),
	placeholder: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#8f8f8f',
		marginTop: -4,
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: 30,
		maxHeight: 30,
		minWidth: '50%',
		borderColor: 'transparent',
		background: state.isDisabled ? '#efeeed' : '#F2F5F6',
		borderRadius: 2,
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		marginTop: -10,
		opacity: state.isDisabled ? 0 : 1,
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	valueContainer: customStyles({
		paddingLeft: 5,
		paddingRight: 5,
	}),
	clearIndicator: customStyles({
		paddingTop: 0,
	}),
};

const autoAssignmentSelectStyle = {
	input: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
	}),
	singleValue: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
		marginTop: 2,
	}),
	placeholder: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#8f8f8f',
		marginTop: -4,
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: 30,
		maxHeight: 30,
		minWidth: '50%',
		borderColor: state.hasValue ? '#56C768' : '#FF5E6F',
		background: state.isDisabled ? '#F2F5F6' : '#ffffff',
		borderRadius: 2,
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		marginTop: -10,
		opacity: state.isDisabled ? 0 : 1,
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	valueContainer: customStyles({
		paddingLeft: 5,
		paddingRight: 5,
	}),
	clearIndicator: customStyles({
		paddingTop: 0,
	}),
	noOptionsMessage: (base) => ({
		...base,
		color: ' #25282B',
		fontSize: '14px',
		fontWeight: 400,
		lineHeight: '19px',
		letterSpacing: '0px',
		textAlign: 'left',
	}),
};

const autoAssignmentMemberSelectStyle = {
	input: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
	}),
	singleValue: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
		marginTop: 2,
	}),
	placeholder: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#8f8f8f',
		marginTop: -4,
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: 30,
		maxHeight: 30,
		minWidth: '50%',
		borderColor: '#C4C4C4',
		background: state.isDisabled ? '#F2F5F6' : '#ffffff',
		borderRadius: 2,
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		marginTop: -10,
		opacity: state.isDisabled ? 0 : 1,
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	valueContainer: customStyles({
		paddingLeft: 5,
		paddingRight: 5,
	}),
	clearIndicator: customStyles({
		paddingTop: 0,
	}),
	noOptionsMessage: (base) => ({
		...base,
		color: ' #25282B',
		fontSize: '14px',
		fontWeight: 400,
		lineHeight: '19px',
		letterSpacing: '0px',
		textAlign: 'left',
	}),
};
const issueCategorySelectStyles = {
	...siteSelectStyles,
	option,
	noOptionsMessage,
	control: (provided, state) => ({
		...provided,
		minHeight: 'unset',
		minWidth: '150px',
		border: 'solid #C4C4C4 1px',
		background: state.isDisabled ? '#ccc' : '#FFFFFF',
		borderRadius: 2,
	}),
	placeholder: customStyles({
		fontSize: '0.875rem',
		fontWeight: 'normal',
		lineHeight: '1.4375rem',
		color: '#8F8F8F',
		width: '100%',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		opacity: state.isDisabled ? 0 : 1,
		color: '#535353',
		padding: '6px 8px',
	}),
	singleValue: customStyles({
		fontSize: '0.875rem',
		fontWeight: 'normal',
		lineHeight: '1.4375rem',
		color: '#25282B',
		margin: '0px',
	}),
	input: customStyles({
		fontSize: '0.875rem',
		fontWeight: 'normal',
		lineHeight: '1.4375rem',
		color: '#25282B',
		padding: '0px',
		margin: '0px',
	}),
	valueContainer: customStyles({
		padding: '7px 8px 8px 8px',
	}),
};

const issueDashboardSelectStyles = {
	input: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
	}),
	singleValue: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
		marginTop: 2,
	}),
	placeholder: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#8f8f8f',
		marginTop: -4,
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: 30,
		maxHeight: 30,
		minWidth: 130,
		borderColor: 'transparent',
		background: state.isDisabled ? '#efeeed' : '#F2F5F6',
		borderRadius: 2,
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		marginTop: -10,
		opacity: state.isDisabled ? 0 : 1,
		color: '#535353',
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	valueContainer: customStyles({
		paddingLeft: 5,
		paddingRight: 5,
	}),
};

const downloadFilterSelectStyles = {
	...siteSelectStyles,
	option,
	noOptionsMessage,
	control: (provided, state) => ({
		...provided,
		minHeight: '30px',
		maxHeight: '30px',
		minWidth: '150px',
		borderColor: state.hasValue ? '#5049B2' : 'transparent',
		background: state.isDisabled ? '#efeeed' : '#F2F5F6',
		opacity: state.isDisabled ? 0.5 : 1,
		borderRadius: 2,
		marginRight: '12px',
	}),
};

const clientLoginSelectStyles = {
	...siteSelectStyles,
	option,
	noOptionsMessage,
	container: customStyles({
		width: '100%',
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: '30px',
		maxHeight: '30px',
		background: state.isDisabled ? '#efeeed' : '#F2F5F6',
		opacity: state.isDisabled ? 0.5 : 1,
		borderRadius: 2,
		borderColor: state.isFocused ? '#b3b3b3' : 'transparent',
		transition: '200ms all ease-in-out',
		boxShadow: state.isFocused && '0px 4px 32px -8px rgba(0, 0, 0, 0.2)',
		':hover': {
			boxShadow: '0px 4px 32px -8px rgba(0, 0, 0, 0.2)',
			borderColor: state.isFocused ? '#b3b3b3' : 'transparent',
		},
	}),
};

const questionCategorySelectStyles = {
	...downloadFilterSelectStyles,
	option,
	noOptionsMessage,
	singleValue: (provided, _state) => ({
		...provided,
		fontWeight: 'normal',
		fontSize: '18px',
		lineHeight: '25px',
		margin: 0,
	}),
	input: (provided, _state) => ({
		...provided,
		fontWeight: 'normal',
		fontSize: '18px',
		lineHeight: '25px',
		margin: 0,
	}),
	valueContainer: (provided, _state) => ({
		...provided,
		padding: 0,
	}),
	indicatorsContainer: (provided, _state) => ({
		...provided,
		paddingTop: '10px',
	}),
	control: (provided, state) => ({
		...provided,
		maxHeight: '40px',
		minWidth: '150px',
		padding: '0px 0px 0px 20px',
		borderColor: state.hasValue ? '#3cd070' : '#f93c1c',
		background: state.isDisabled ? '#efeeed' : '#fff',
		opacity: state.isDisabled ? 0.5 : 1,
		borderRadius: 2,
		marginTop: '8px',
		marginBottom: '16px',
	}),
};

const createIssueStyles = {
	control: (provided, state) => ({
		...provided,
		minHeight: 'unset',
		border: '1px solid #C4C4C4',
		background: state.isDisabled ? '#ccc' : '#FFFFFF',
		cursor: state.isDisabled ? 'not-allowed' : 'pointer',
		borderRadius: 2,
	}),
	placeholder: issueCategorySelectStyles.placeholder,
	dropdownIndicator: (provided, state) => ({
		...provided,
		color: state.isDisabled ? '#E2E2E2' : '#535353',
		padding: '6px 8px',
	}),
	singleValue: issueCategorySelectStyles.singleValue,
	input: issueCategorySelectStyles.input,
	valueContainer: issueCategorySelectStyles.valueContainer,
	option: customStyles({
		fontSize: '12px',
	}),
};
const profileSelectStyle = {
	...siteSelectStyles,
	input: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
		width: '100%',
	}),
};

const salesTargetSelectStyles = {
	input: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
	}),
	singleValue: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
		marginTop: 2,
	}),
	placeholder: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#8f8f8f',
		marginTop: -4,
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: 30,
		maxHeight: 30,
		minWidth: '100px',
		borderColor: 'transparent',
		background: state.isDisabled ? '#efeeed' : '#fafafa',
		borderRadius: 2,
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		marginTop: -10,
		opacity: state.isDisabled ? 0 : 1,
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	valueContainer: customStyles({
		paddingLeft: 5,
		paddingRight: 5,
	}),
};

const salesBulkSelectStyles = {
	input: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '15px',
		color: '#535353',
		height: '25px',
		marginTop: 0,
	}),
	singleValue: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '15px',
		color: '#535353',
		height: '25px',
		marginTop: 1,
	}),
	placeholder: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '15px',
		color: '#8f8f8f',
		marginTop: -1,
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: 25,
		maxHeight: 25,
		minWidth: '100px',
		borderColor: 'transparent',
		background: state.isDisabled ? '#efeeed' : '#fafafa',
		borderRadius: 2,
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		marginTop: -10,
		opacity: state.isDisabled ? 0 : 1,
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	valueContainer: customStyles({
		paddingLeft: 5,
		paddingRight: 5,
	}),
	menu: customStyles({
		maxHeight: '180px',
	}),
	menuList: customStyles({
		maxHeight: '180px',
	}),
};

const timeSelectStyles = {
	input: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
	}),
	singleValue: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
		marginTop: 2,
	}),
	placeholder: (provided, state) => ({
		...provided,
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: state.isDisabled ? '#e2e2e2' : '#8f8f8f',
		marginTop: -4,
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: 30,
		maxHeight: 30,
		width: '100%',
		minWidth: '80px',
		borderColor: 'transparent',
		background: state.isDisabled ? '#f4f4f4' : '#F2F5F6',
		borderRadius: 2,
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		marginTop: -10,
		opacity: state.isDisabled ? 0 : 1,
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	valueContainer: customStyles({
		paddingLeft: 5,
		paddingRight: 5,
	}),
	menuList: (provided) => ({
		...provided,
		maxHeight: '180px',
	}),
	noOptionsMessage: (provided) => ({
		...provided,
		fontSize: '12px',
	}),
};

const promotionSelectStyles = {
	input: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
	}),
	singleValue: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
		marginTop: 2,
	}),
	placeholder: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#8f8f8f',
		marginTop: -4,
	}),
	menu: (provided) => ({
		...provided,
		maxWidth: '70%',
	}),
	control: (provided) => ({
		...provided,
		minHeight: 30,
		maxHeight: 30,
		width: '70%',
		minWidth: '160px',
		borderColor: 'transparent',
		background: '#F2F5F6',
		borderRadius: 2,
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		marginTop: -10,
		opacity: state.isDisabled ? 0 : 1,
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	valueContainer: customStyles({
		paddingLeft: 5,
		paddingRight: 5,
	}),
};

const siteScheduleSelectStyles = {
	...salesBulkSelectStyles,
	control: (provided, state) => ({
		...provided,
		minHeight: 25,
		maxHeight: 25,
		minWidth: '100px',
		borderColor: 'transparent',
		background: state.isDisabled ? '#efeeed' : '#f2f5f6',
		borderRadius: 2,
	}),
	noOptionsMessage: (provided) => ({
		...provided,
		fontSize: '12px',
	}),
	clearIndicator: () => ({
		display: 'none',
	}),
};

const offDaysSelectStyles = {
	input: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
	}),
	menu: customStyles({
		marginTop: -5,
		zIndex: 99999,
	}),
	singleValue: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
		marginTop: 2,
	}),
	placeholder: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#8f8f8f',
		marginTop: -4,
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: 30,
		maxHeight: 30,
		minWidth: '100px',
		borderColor: 'transparent',
		background: state.isDisabled ? '#efeeed' : '#fafafa',
		borderRadius: 2,
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		marginTop: -10,
		opacity: state.isDisabled ? 0 : 1,
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	valueContainer: customStyles({
		paddingLeft: 5,
		paddingRight: 5,
	}),
};

const trendsSelectStyles = {
	...questionSelectStyles,
	noOptionsMessage: (provided) => ({
		...provided,
		fontSize: '12px',
	}),
	menu: (provided) => ({
		...provided,
		zIndex: 10,
	}),
	menuList: (provided) => ({
		...provided,
		zIndex: 10,
	}),
};

const dashboardSelectStyles = {
	input: (provided, state) => ({
		...provided,
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: state.isDisabled ? '#8f8f8f' : '#535353',
		height: '30px',
	}),
	singleValue: (provided, state) => ({
		...provided,
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: 18,
		color: state.isDisabled ? '#8f8f8f' : '#535353',
		fontStyle: state.isDisabled ? 'italic' : 'normal',
		height: 30,
		display: 'inline-flex',
		alignItems: 'center',
	}),
	placeholder: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#8f8f8f',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: 30,
		maxHeight: 30,
		minWidth: '50%',
		borderColor: 'transparent',
		background: state.isDisabled ? '#efeeed' : '#F2F5F6',
		borderRadius: 2,
	}),
	valueContainer: (provided) => ({
		...provided,
		height: 30,
		position: 'relative',
		paddingLeft: 5,
		paddingRight: 5,
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		display: 'inline-flex',
		alignItems: 'center',
		opacity: state.isDisabled ? 0 : 1,
	}),
	indicatorsContainer: (provided) => ({
		...provided,
		height: 30,
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	noOptionsMessage: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		textAlign: 'center',
		lineHeight: '18px',
		padding: '4px',
		color: '#a8a8aa',
	}),
};

const courseSelectStyles = {
	input: (provided, state) => ({
		...provided,
		fontFamily: 'Poppins',
		fontSize: '14px',
		fontWeight: 'normal',
		lineHeight: '23px',
		color: state.isDisabled ? '#8f8f8f' : '#535353',
		height: '40px',
	}),
	singleValue: (provided, state) => ({
		...provided,
		fontFamily: 'Poppins',
		fontSize: '14px',
		fontWeight: 'normal',
		lineHeight: 23,
		color: state.isDisabled ? '#8f8f8f' : '#535353',
		fontStyle: state.isDisabled ? 'italic' : 'normal',
		height: 40,
		display: 'inline-flex',
		alignItems: 'center',
	}),
	placeholder: customStyles({
		fontFamily: 'Poppins',
		fontSize: '14px',
		fontWeight: 'normal',
		lineHeight: '23px',
		color: '#8f8f8f',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: 40,
		maxHeight: 40,
		borderColor: '#c4c4c4',
		background: state.isDisabled ? '#efeeed' : 'transparent',
		borderRadius: 4,
	}),
	valueContainer: (provided) => ({
		...provided,
		height: 40,
		position: 'relative',
		paddingLeft: 5,
		paddingRight: 5,
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		display: 'inline-flex',
		alignItems: 'center',
		opacity: state.isDisabled ? 0 : 1,
	}),
	indicatorsContainer: (provided) => ({
		...provided,
		height: 40,
	}),
	option: customStyles({
		fontFamily: 'Poppins',
		fontSize: '14px',
	}),
	noOptionsMessage: customStyles({
		fontFamily: 'Poppins',
		fontSize: '14px',
		fontWeight: 'normal',
		textAlign: 'center',
		lineHeight: '23px',
		padding: '4px',
		color: '#a8a8aa',
	}),
};

const checkboxSelectStyles = {
	...dashboardSelectStyles,
	input: (provided, state) => ({
		...provided,
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: state.isDisabled ? '#8f8f8f' : '#535353',
	}),
};

const groupDepartmentSelectStyles = {
	input: customStyles({
		fontSize: '0.65rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
	}),
	singleValue: customStyles({
		fontSize: '0.65rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
		marginTop: 2,
	}),
	placeholder: customStyles({
		fontSize: '0.65rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#8f8f8f',
		marginTop: -4,
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: 30,
		maxHeight: 30,
		minWidth: '50%',
		borderColor: 'transparent',
		background: state.isDisabled ? '#efeeed' : '#F2F5F6',
		borderRadius: 2,
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		marginTop: -10,
		opacity: state.isDisabled ? 0 : 1,
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	valueContainer: customStyles({
		paddingLeft: 5,
		paddingRight: 5,
	}),
};

const journeyPlanSelectStyle = {
	input: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
	}),
	singleValue: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
		marginTop: 2,
	}),
	placeholder: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#8f8f8f',
		marginTop: -4,
	}),
	control: (provided, state) => {
		const isValid = state.selectProps.id.includes('valid');
		const isOptional = state.selectProps.id.includes('optional');
		return {
			...provided,
			minHeight: 30,
			maxHeight: 30,
			minWidth: '50%',
			borderColor: 'transparent',
			background: state.isDisabled ? '#fafafa' : '#f3f5f6',
			borderRadius: 2,
			border: isOptional
				? '1px solid transparent'
				: state.hasValue || isValid
				? '1px solid #3BD070'
				: '1px solid #EB5757',
		};
	},
	dropdownIndicator: (provided, state) => ({
		...provided,
		marginTop: -10,
		opacity: state.isDisabled ? 0 : 1,
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	valueContainer: customStyles({
		paddingLeft: 5,
		paddingRight: 5,
	}),
	clearIndicator: customStyles({
		paddingTop: 0,
	}),
};

const productSelectStyle = {
	input: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
	}),
	singleValue: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#535353',
		height: '30px',
		marginTop: 2,
	}),
	placeholder: (provided, state) => {
		return {
			...provided,
			fontSize: '0.75rem',
			fontWeight: 'normal',
			lineHeight: '18px',
			color: state.isDisabled ? '#ddd' : '#8f8f8f',
			marginTop: -4,
		};
	},
	menu: (provided) => ({
		...provided,
		maxWidth: '50%',
	}),
	control: (provided, state) => {
		return {
			...provided,
			minHeight: 30,
			maxHeight: 30,
			maxWidth: '50%',
			borderColor: 'transparent',
			background: state.isDisabled ? '#fafafa' : '#f3f5f6',
			borderRadius: 2,
			border: state.isDisabled ? '1px solid #fafafa' : '1px solid #ddd',
		};
	},
	dropdownIndicator: (provided, state) => ({
		...provided,
		marginTop: -10,
		opacity: state.isDisabled ? 0 : 1,
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	valueContainer: customStyles({
		paddingLeft: 5,
		paddingRight: 5,
	}),
};

const productModalSelectStyle = {
	...productSelectStyle,
	control: (provided, state) => {
		return {
			...provided,
			minHeight: 30,
			maxHeight: 30,
			borderColor: 'transparent',
			background: state.isDisabled ? '#fafafa' : '#f3f5f6',
			borderRadius: 2,
			border: state.isDisabled ? '1px solid #fafafa' : '1px solid #ddd',
		};
	},
};
const issuesTrackerSelectStyles = {
	input: (provided, state) => ({
		...provided,
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: state.isDisabled ? '#8f8f8f' : '#535353',
		height: '30px',
	}),
	singleValue: (provided, state) => ({
		...provided,
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: 18,
		color: state.isDisabled ? '#8f8f8f' : '#535353',
		fontStyle: state.isDisabled ? 'italic' : 'normal',
		height: 30,
		display: 'inline-flex',
		alignItems: 'center',
	}),
	placeholder: customStyles({
		fontSize: '0.75rem',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#8f8f8f',
		marginTop: 0,
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: 30,
		maxHeight: 30,
		minWidth: '90px',
		borderColor: 'transparent',
		background: state.isDisabled ? '#efeeed' : '#F2F5F6',
		borderRadius: 2,
	}),
	valueContainer: (provided) => ({
		...provided,
		height: 30,
		position: 'relative',
		paddingLeft: 5,
		paddingRight: 5,
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		display: 'inline-flex',
		alignItems: 'center',
		opacity: state.isDisabled ? 0 : 1,
	}),
	indicatorsContainer: (provided) => ({
		...provided,
		height: 30,
	}),
	option: customStyles({
		fontSize: '12px',
	}),
	container: customStyles({
		width: '195px',
	}),
};
const competitorSelectStyles = {
	input: (provided, state) => ({
		...provided,
		fontSize: '10px',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: state.isDisabled ? '#8f8f8f' : '#535353',
		height: '30px',
	}),
	singleValue: (provided, state) => ({
		...provided,
		fontSize: '10px',
		fontWeight: 'normal',
		lineHeight: 18,
		color: state.isDisabled ? '#8f8f8f' : '#535353',
		fontStyle: state.isDisabled ? 'italic' : 'normal',
		height: 30,
		display: 'inline-flex',
		alignItems: 'center',
	}),
	placeholder: customStyles({
		fontSize: '10px',
		fontWeight: 'normal',
		lineHeight: '18px',
		color: '#8f8f8f',
		marginTop: 0,
	}),
	control: (provided, state) => ({
		...provided,
		minHeight: 30,
		maxHeight: 30,
		minWidth: '200px',
		borderColor: 'transparent',
		background: state.isDisabled ? '#efeeed' : '#F2F5F6',
		borderRadius: 2,
	}),
	valueContainer: (provided) => ({
		...provided,
		height: 30,
		position: 'relative',
		paddingLeft: 5,
		paddingRight: 5,
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		display: 'inline-flex',
		alignItems: 'center',
		opacity: state.isDisabled ? 0 : 1,
	}),
	indicatorsContainer: (provided) => ({
		...provided,
		height: 30,
	}),
	option: customStyles({
		fontSize: '12px',
	}),
};

const whiteBgSelectStyles = {
	...dashboardSelectStyles,
	control: (provided) => ({
		...provided,
		backgroundColor: '#fff',
		alignItems: 'center',
	}),
};

const validationStyles = {
	...dashboardSelectStyles,
	control: (provided) => ({
		...provided,
		backgroundColor: '#fff',
		alignItems: 'center',
		border: '1px solid red',
	}),
};

const selectStyles = (hasError) => {
	return {
		control: (provided, state) => {
			let styles = {
				...provided,
				boxShadow: 0,
				background: '#FFFFFF',
				borderColor: '#C4C4C4',
				'&:hover': {
					borderColor: '#C4C4C4',
				},
				minHeight: 'unset',
				borderWidth: '1px',
				borderStyle: 'solid',
				outline: '1px solid transparent !important',
				borderRadius: 4,
				cursor: 'pointer',
			};

			const disabledStyles = {
				background: '#F9F8F8',
			};

			const focusedStyles = {
				boxShadow: 0,
				background: '#F5F9FF',
				borderColor: '#7E78EA',
				outline: '1px solid #7E78EA !important',
				'&:hover': {
					borderColor: '#7E78EA',
					outline: '1px solid #7E78EA !important',
				},
			};

			const errorStyles = {
				boxShadow: 0,
				borderColor: '#FF5E6F',
				outline: '1px solid #FF5E6F !important',
				'&:hover': {
					borderColor: '#FF5E6F',
					outline: '1px solid #FF5E6F !important',
				},
			};

			switch (true) {
				case state.isFocused:
					styles = { ...styles, ...focusedStyles };
					break;
				case state.isDisabled:
					styles = { ...styles, ...disabledStyles };
					break;
				case hasError:
					styles = { ...styles, ...errorStyles };
					break;
			}

			return styles;
		},
		placeholder: createIssueStyles.placeholder,
		dropdownIndicator: createIssueStyles.dropdownIndicator,
		singleValue: createIssueStyles.singleValue,
		input: createIssueStyles.input,
		valueContainer: customStyles({
			margin: '6px 8px 6px 8px',
			padding: '0',
			height: '1.4375rem',
		}),
		option: (provided, state) => {
			let style = {
				...provided,
				cursor: 'pointer',
				fontSize: '0.875rem',
				lineHeight: '1.4375rem',
				background: 'unset',
				color: '#25282B',
				'&:hover': {
					background: '#EAE9F9',
				},
			};

			if (state.isSelected) {
				style = {
					...style,
					background: 'unset',
					'&:hover': {
						background: '#EAE9F9',
					},
				};
			}

			if (state.isDisabled) {
				style = {
					...style,
					color: '#AAAAAA',
					background: '#F9F8F8',
					cursor: 'auto',
					'&:hover': {
						background: 'F9F8F8',
					},
				};
			}

			return style;
		},
		menuPortal: (provided) => ({
			...provided,
			zIndex: 9999,
		}),
		menu: (provided) => ({
			...provided,
			padding: '4px 0px',
			borderRadius: '2px',
			zIndex: 2,
		}),
	};
};

const multiSelectStyles = (hasError) => {
	const selectStyleInstance = selectStyles(hasError);
	return {
		control: selectStyleInstance.control,
		placeholder: selectStyleInstance.placeholder,
		dropdownIndicator: selectStyleInstance.dropdownIndicator,
		singleValue: selectStyleInstance.singleValue,
		input: selectStyleInstance.input,
		valueContainer: selectStyleInstance.valueContainer,
		menu: (provided) => ({
			...provided,
			display: 'flex',
			flexDirection: 'column',
			paddingTop: '4px',
			borderRadius: '2px',
		}),
		menuList: (provided) => ({
			...provided,
			flex: '0 1 auto',
			maxHeight: '250px',
		}),
		option: selectStyleInstance.option,
	};
};

const multiSelectFilterStyles = (hasError, dimPlaceholder) => {
	return {
		...multiSelectStyles(hasError),
		placeholder: (provided) => ({
			...provided,
			color: dimPlaceholder ? provided.color : neutral[900],
			fontSize: '0.875rem',
			lineHeight: '1.4375rem',
			width: '100%',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		}),
	};
};

const skuFilterSelectStyles = {
	...multiSelectStyles(false),
	container: (base) => ({
		...base,
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		maxHeight: '100%',
	}),
	menu: (base) => ({
		...base,
		border: 'none',
		boxShadow: 'none',
		top: 'auto',
		display: 'flex',
		flexGrow: 1,
		position: 'relative',
		zIndex: 'auto',
		height: '100%',
		overflowY: 'scroll',
	}),
	menuList: (base) => ({
		...base,
		maxHeight: '100%',
		width: '100%',
	}),
};

const bulkOpsMultiSelectStyles = (hasError) => {
	const selectStyleInstance = selectStyles(hasError);
	return {
		control: selectStyleInstance.control,
		placeholder: selectStyleInstance.placeholder,
		dropdownIndicator: selectStyleInstance.dropdownIndicator,
		singleValue: selectStyleInstance.singleValue,
		input: selectStyleInstance.input,
		valueContainer: selectStyleInstance.valueContainer,
		menu: (provided) => ({
			...provided,
			display: 'flex',
			flexDirection: 'column',
			paddingTop: '4px',
			borderRadius: '2px',
			maxWidth: '23.21vw',
			width: 'max-content',
			minWidth: '100%',
		}),
		menuList: (provided) => ({
			...provided,
			flex: '0 1 auto',
			maxHeight: '250px',
		}),
		option: selectStyleInstance.option,
	};
};

const bulkOpsMultiSelectFilterStyles = (hasError, dimPlaceholder) => {
	return {
		...bulkOpsMultiSelectStyles(hasError),
		placeholder: (provided) => ({
			...provided,
			color: dimPlaceholder ? provided.color : neutral[900],
			fontSize: '0.875rem',
			lineHeight: '1.4375rem',
			width: '100%',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		}),
	};
};

const createIssueApprovalFormStyles = (hasError) => {
	return {
		...selectStyles(hasError),
		placeholder: customStyles({
			fontSize: '12px',
			fontWeight: 'normal',
			lineHeight: '1.4375rem',
			color: '#8F8F8F',
			width: '100%',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		}),
	};
};

export {
	createIssueStyles,
	generalSelectTheme,
	emailSelectStyles,
	generalSelectStyles,
	recipientSelectStyles,
	courseSelectStyles,
	customStyles,
	issueSelectStyles,
	inventorySelectStyles,
	salesTargetSelectStyles,
	siteScheduleSelectStyles,
	salesBulkSelectStyles,
	poSelectStyles,
	questionSelectStyles,
	siteSelectStyles,
	userGroupSelectStyles,
	promotionSelectStyles,
	offDaysSelectStyles,
	timeSelectStyles,
	timezoneSelectStyles,
	trendsSelectStyles,
	profileSelectStyle,
	flagSelectStyles,
	dashboardSelectStyles,
	downloadFilterSelectStyles,
	clientLoginSelectStyles,
	questionCategorySelectStyles,
	issueCategorySelectStyles,
	questionnaireTemplateDownloadStyles,
	groupDepartmentSelectStyles,
	journeyPlanSelectStyle,
	radiuSelectStyles,
	productSelectStyle,
	productModalSelectStyle,
	issuesTrackerSelectStyles,
	issueDashboardSelectStyles,
	competitorSelectStyles,
	autoAssignmentSelectStyle,
	autoAssignmentMemberSelectStyle,
	weightScoreSelectStyles,
	inventoryNewSelectStyles,
	checkboxSelectStyles,
	whiteBgSelectStyles,
	validationStyles,
	selectStyles,
	multiSelectStyles,
	skuFilterSelectStyles,
	multiSelectFilterStyles,
	bulkOpsMultiSelectFilterStyles,
	bulkOpsMultiSelectStyles,
	createIssueApprovalFormStyles,
};
