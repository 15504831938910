import { ActionType, createReducer } from 'typesafe-actions';
import { ReportsHubStore } from './reportsHub.types';
import * as actions from './reportsHub.action';
import * as types from './reportsHub.actionTypes';
import { ReportType } from 'components/reportshub/ReportHub.types';

const initialState: ReportsHubStore = {
	filters: {
		reportType: '',
		scheduleStatus: '',
		imageValidationResult: [],
		issueStatus: [],
		issueSource: [],
		approvalStatus: [],
		issuePriority: [],
		issueSeverity: [],
		departments: [],
		sites: [],
		users: [],
		questionnaires: [],
		reportFormat: '',
		isWithAttachments: false,
		isConsolidated: false,
		startDate: null,
		endDate: null,
		isReportBasic: true,
	},
	tableType: ReportType.ALL,
	reportsTable: {
		reportsList: {
			docs: [],
			limit: 0,
			page: 0,
			totalDocs: 0,
			totalPages: 0,
		},
		isLoading: false,
		limit: 10,
		page: 1,
		sortDirections: 'desc',
		sortFields: 'requestedOn',
	},
	reportsCount: {
		data: null,
		isLoading: false,
		isSuccess: false,
	},
	showLimitSpec: false,
	isNewCustomFilterModalOpen: false,
	customFilters: [],
	isCustomFilterLoading: false,
	isSavedFilter: false,
	isFilterOpen: false,
};

export type ReportsHubAction = ActionType<typeof actions>;

export const reportsHubReducer = createReducer<ReportsHubStore, ReportsHubAction>(initialState)
	.handleAction(actions.fetchReportsHubTableAsync.request, (state) => ({
		...state,
		reportsTable: {
			...state.reportsTable,
			isLoading: true,
		},
	}))
	.handleAction(actions.fetchReportsHubTableAsync.success, (state, action) => ({
		...state,
		reportsTable: {
			...state.reportsTable,
			isLoading: false,
			reportsList: action.payload,
		},
	}))
	.handleAction(actions.fetchReportsHubTableAsync.failure, (state, action) => ({
		...state,
		reportsTable: {
			...state.reportsTable,
			isLoading: false,
			reportsList: initialState.reportsTable.reportsList,
		},
	}))

	.handleAction(actions.fetchReportsCountAsync.request, (state) => ({
		...state,
		reportsCount: {
			data: null,
			isLoading: true,
			isSuccess: false,
		},
	}))
	.handleAction(actions.fetchReportsCountAsync.success, (state, action) => ({
		...state,
		reportsCount: {
			data: action.payload,
			isLoading: false,
			isSuccess: true,
		},
	}))
	.handleAction(actions.fetchReportsCountAsync.failure, (state, action) => ({
		...state,
		reportsCount: {
			data: null,
			isLoading: false,
			isSuccess: false,
		},
	}))

	.handleAction(actions.fetchReportsFilterAsync.request, (state) => ({
		...state,
		isCustomFilterLoading: true,
	}))
	.handleAction(actions.fetchReportsFilterAsync.success, (state, action) => ({
		...state,
		customFilters: action.payload,
		isCustomFilterLoading: false,
	}))
	.handleAction(actions.fetchReportsFilterAsync.failure, (state, action) => ({
		...state,
		isCustomFilterLoading: false,
	}))

	.handleAction(types.SET_FILTER_DATE as any, (state, action) => {
		return {
			...state,
			filters: {
				...state.filters,
				startDate: action.payload.startDate,
				endDate: action.payload.endDate,
			},
		};
	})
	.handleAction(types.SET_TABLE_TYPE as any, (state, action) => {
		return {
			...state,
			tableType: action.payload,
		};
	})
	.handleAction(types.SET_PAGE_INDEX as any, (state, action) => {
		return {
			...state,
			reportsTable: {
				...state.reportsTable,
				page: action.payload,
			},
		};
	})
	.handleAction(types.SET_PAGE_SORT as any, (state, action) => {
		return {
			...state,
			reportsTable: {
				...state.reportsTable,
				sortDirections: action.payload.sortDirections,
				sortFields: action.payload.sortFields,
			},
		};
	})
	.handleAction(types.SET_SINGLE_SELECT as any, (state, action) => {
		return {
			...state,
			filters: {
				...state.filters,
				[action.payload.key]: action.payload.value,
			},
		};
	})
	.handleAction(types.SET_MULTI_SELECT as any, (state, action) => {
		return {
			...state,
			filters: {
				...state.filters,
				[action.payload.key]: action.payload.value,
			},
		};
	})
	.handleAction(types.SET_RADIO_VALUE as any, (state, action) => {
		return {
			...state,
			filters: {
				...state.filters,
				[action.payload.key]: action.payload.value,
			},
		};
	})
	.handleAction(types.SET_REPORT_TYPE as any, (state, action) => {
		return {
			...state,
			filters: {
				...state.filters,
				[action.payload.key]: action.payload.value,
				scheduleStatus: '',
				imageValidationResult: [],
				issueStatus: [],
				issueSource: [],
				issuePriority: [],
				issueSeverity: [],
			},
		};
	})
	.handleAction(types.RESET_REPORT_COUNT as any, (state) => {
		return {
			...state,
			reportsCount: initialState.reportsCount,
		};
	})
	.handleAction(types.RESET_FILTERS as any, (state) => {
		return {
			...state,
			filters: initialState.filters,
		};
	})
	.handleAction(types.SET_LIMIT_SPEC as any, (state, action) => {
		return {
			...state,
			showLimitSpec: action.payload,
		};
	})
	.handleAction(types.SET_IS_SAVED_FILTER as any, (state, action) => {
		return {
			...state,
			isSavedFilter: action.payload,
		};
	})
	.handleAction(types.SET_REPORT_COUNT_SUCCESS as any, (state, action) => {
		return {
			...state,
			reportsCount: {
				...state.reportsCount,
				isSuccess: action.payload,
			},
		};
	})
	.handleAction(types.SET_CUSTOM_FILTER_MODAL as any, (state, action) => {
		return {
			...state,
			isNewCustomFilterModalOpen: action.payload,
		};
	})
	.handleAction(types.SET_CUSTOM_FILTER as any, (state, action) => {
		return {
			...state,
			filters: action.payload,
		};
	})
	.handleAction(types.SET_IS_FILTER_OPEN as any, (state, action) => {
		return {
			...state,
			isFilterOpen: action.payload,
		};
	});
