import { action, ActionType, createReducer } from 'typesafe-actions';
import update from 'immutability-helper';
import * as actions from './IssueTrackerSettings.action';
import * as types from './IssueTrackerSettings.actionTypes';
import { AutoDueDate, IssueTrackerSettings } from '@nimbly-technologies/nimbly-common';
import { autoAssignmentDefaultHierarchy } from '@nimbly-technologies/nimbly-common/src/enumerators/autoAssignmentHierarchy';
import { AutoApprovalAction, ReminderPeriod } from '@nimbly-technologies/nimbly-common/lib/enumerators';

export type IssueTrackerSettingsAction = ActionType<typeof actions>;

interface IssueTrackerSettingsState {
	error: string;
	isLoading: boolean;
	defaultAutoDueDate: AutoDueDate | undefined;
	issueTrackerSettings: IssueTrackerSettings;
}

export const initialState: IssueTrackerSettingsState = {
	error: '',
	isLoading: false,
	defaultAutoDueDate: undefined,
	issueTrackerSettings: {
		issueTrackerSettingsID: '',
		organizationID: '',
		useAutoDueDate: false,
		autoAssignmentHierarchy: ['schedule', 'question', 'category', 'questionnaire', 'department', 'site', 'reporter'],
		priorities: {
			p0: {
				iconID: '',
				iconURL: '',
				color: '',
				text: 'High',
			},
			p1: {
				iconID: '',
				iconURL: '',
				color: '',
				text: 'Medium',
			},
			p2: {
				iconID: '',
				iconURL: '',
				color: '',
				text: 'Low',
			},
		},
		useAutoAssignment: false,
		highlightRecurringIssues: false,
		highlightRecurringIssuesCount: 0,
		allowAutoEscalationIssue: false,
		allowAuditorEditIssueMeta: false,
		organizationPrefix: '',
		latestIssueSequenceNumber: 0,
		latestCFissueNumber: 0,
		createdAt: 0,
		createdBy: '',
		updatedAt: 0,
		updatedBy: '',
		issueApprovers: [],
		approvalSLA: {
			useAutoApproval: true,
			duration: 0,
			durationPeriod: ReminderPeriod.DAYS,
			action: AutoApprovalAction.REJECTED,
		},
	},
};

export const issueTrackerSettingsReducer = createReducer<IssueTrackerSettingsState, IssueTrackerSettingsAction>(
	initialState,
)
	.handleType(types.SET_USE_AUTO_DUE_DATE, (state, action) =>
		update(state, {
			issueTrackerSettings: {
				$set: {
					...state.issueTrackerSettings,
					useAutoDueDate: action.payload,
				},
			},
		}),
	)
	.handleType(types.SET_USE_AUTO_ASSIGNMENT, (state, action) =>
		update(state, {
			issueTrackerSettings: {
				$set: {
					...state.issueTrackerSettings,
					useAutoAssignment: action.payload,
				},
			},
		}),
	)
	.handleType(types.SET_HIGHLIGHT_RECURRING_ISSUES, (state, action) =>
		update(state, {
			issueTrackerSettings: {
				$set: {
					...state.issueTrackerSettings,
					highlightRecurringIssues: action.payload,
				},
			},
		}),
	)
	.handleType(types.SET_HIGHLIGHT_RECURRING_ISSUES_COUNT, (state, action) =>
		update(state, {
			issueTrackerSettings: {
				$set: {
					...state.issueTrackerSettings,
					highlightRecurringIssuesCount: action.payload,
				},
			},
		}),
	)
	.handleType(types.SET_ALLOW_AUDITOR_EDIT_ISSUE, (state, action) =>
		update(state, {
			issueTrackerSettings: {
				$set: {
					...state.issueTrackerSettings,
					allowAuditorEditIssueMeta: action.payload,
				},
			},
		}),
	)
	.handleType(types.SET_ALLOW_AUTO_ESCALATION, (state, action) =>
		update(state, {
			issueTrackerSettings: {
				$set: {
					...state.issueTrackerSettings,
					allowAutoEscalationIssue: action.payload,
				},
			},
		}),
	)
	.handleType(types.FETCH_ISSUE_TRACKER_SETTINGS_REQUEST, (state) =>
		update(state, {
			issueTrackerSettings: { $set: initialState.issueTrackerSettings },
			defaultAutoDueDate: { $set: initialState.defaultAutoDueDate },
		}),
	)
	.handleType(types.FETCH_ISSUE_TRACKER_SETTINGS_SUCCESS, (state, action) =>
		update(state, {
			isLoading: { $set: false },
			issueTrackerSettings: { $set: action.payload.issueTrackerSettings },
			defaultAutoDueDate: { $set: action.payload.defaultAutoDueDate },
		}),
	)
	.handleType(types.FETCH_ISSUE_TRACKER_SETTINGS_FAILED, (state, action) =>
		update(state, {
			error: { $set: action.payload.error },
			isLoading: { $set: false },
		}),
	)
	.handleType(types.SET_USE_AUTO_APPROVAL, (state, action) =>
		update(state, {
			issueTrackerSettings: {
				$set: {
					...state.issueTrackerSettings,
					approvalSLA: {
						...state.issueTrackerSettings.approvalSLA,
						useAutoApproval: action.payload,
					},
				},
			},
		}),
	)
	.handleType(types.SET_AUTO_APPROVAL_DURATION, (state, action) =>
		update(state, {
			issueTrackerSettings: {
				$set: {
					...state.issueTrackerSettings,
					approvalSLA: {
						...state.issueTrackerSettings.approvalSLA,
						duration: action.payload,
					},
				},
			},
		}),
	)
	.handleType(types.SET_AUTO_APPROVAL_ACTION, (state, action) =>
		update(state, {
			issueTrackerSettings: {
				$set: {
					...state.issueTrackerSettings,
					approvalSLA: {
						...state.issueTrackerSettings.approvalSLA,
						action: action.payload,
					},
				},
			},
		}),
	)
	.handleType(types.SET_AUTO_APPROVAL_DURATION_PERIOD, (state, action) =>
		update(state, {
			issueTrackerSettings: {
				$set: {
					...state.issueTrackerSettings,
					approvalSLA: {
						...state.issueTrackerSettings.approvalSLA,
						durationPeriod: action.payload,
					},
				},
			},
		}),
	);
